<template>
  <span class="support-icon">
    <slot
      v-if="$slots.default"
    ></slot>
    <Icon 
      v-else
      name="sui_icon_nav_support_24px" 
      size="24px"
      :is-rotate="GB_cssRight" 
    />
    <span 
      v-if="unReadChatNum > 0"
      class="support-icon__un-read"
    ></span>
  </span>
</template>

<script setup>
import { defineProps } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

const { GB_cssRight } = gbCommonInfo

defineProps({
  unReadChatNum: {
    type: Number,
    default: 0
  }
})

</script>

<style lang="less" scoped>
.support-icon {
  position: relative;
  &__un-read {
    position: absolute;
    width: 12/75rem;
    height: 12/75rem;
    background-color: @sui_color_guide;
    border-radius: 50%;
    .right(-.08rem);
    top: -.15rem;
  }
}
</style>
